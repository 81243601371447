import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async login(data) {
    let result = await axios.post(`${API_ROUTES.auth.login}`, data);

    return result;
  },

  async save(uuid, data) {
    let result = await axios.post(`${API_ROUTES.auth.register}`, data);

    return result;
  },

  async passwordRecovery(data) {
    let result = await axios.post(`${API_ROUTES.auth.recovery}`, data);

    return result;
  },

  async changePassword(data) {
    let result = await axios.post(`${API_ROUTES.auth.change}`, data);

    return result;
  },

  async changePasswordFistSession(data) {
    let result = await axios.post(`${API_ROUTES.auth.changeFirst}`, data);

    return result;
  },

  async logout(data) {
    let result = await axios.post(`${API_ROUTES.logout.save}`, data);

    return result;
  }
}